import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';
import ContactContent from '../components/contactContent/ContactContent';
import {useContactContent} from '../graphql/contact/contact';

const Contact = () => {
  const contactContent = useContactContent();

  return (
    <Layout>
      <SEO title="Contact" />
      <ContactContent content={contactContent} />
    </Layout>
  );
};

export default Contact;
