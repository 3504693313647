import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import './ContactContent.less';

const ContactContent = ({content}) => (
  <Container className="contact">
    <Row className="contact__cards mb-5">
      {content?.map((item, index) => (
        <Col
          xs={12}
          md={6}
          lg={4}
          className="my-3 animate__animated animate__bounceInDown"
          key={index}
        >
          <Card className="contact__card">
            <Card.Body>
              <div className="contact__card-header">
                <i className={`${item.icon} pt-1 mr-2`} />
                <Card.Title className="contact__card-title">
                  <span>{item.title}</span>
                </Card.Title>
              </div>
              {item.content.map((contentItem, contentIndex) => (
                <Card.Text
                  className="ml-4 mb-0"
                  key={contentIndex}
                  dangerouslySetInnerHTML={{
                    __html: contentItem,
                  }}
                />
              ))}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    <div className="mapouter mb-5">
      <div className="gmap_canvas">
        <iframe
          title="location"
          width="100%"
          height="400px"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Tudor%20Vladimirescu%2084C%20Baia%20Mare%20Romania&t=&z=17&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
      </div>
    </div>
  </Container>
);

export default ContactContent;
